import { useCallback } from 'react';
import {
  OneOfChildElements,
  Project,
} from '../../../shared/models/project.interface';
import {
  addRecipes,
  getCorrespondingProductRecord,
  getCorrespondingRecipeRecord,
  getProductsToAdd,
  getRecipesToAdd,
  printRecord,
  replaceProjectRecipeIds,
} from '../helpers/import_helpers';
import { getSelectedOrganization } from '../store/organization';
import { Recipe } from '../../../shared/models/recipe.interface';
import { mapFilterRecord } from '../../../shared/helpers/object_helpers';
import {
  getProductIdsInRecipes,
  getRecipesUsedInProject,
} from '../../../shared/helpers/recipe_helpers';
import { toLookup } from '../../../shared/helpers/utils.helpers';
import { getCreateRecipe, getRecipeLookup } from '../store/recipe/recipe.hook';
import { ProductRecord } from '../../../shared/models/product.interface';
import {
  getProjectProductsRecord,
  replaceProductIds,
  updateProductsInProject,
} from '../../../shared/helpers/product_helpers';
import { getCreateProduct, getProductsLookup } from '../store/product';
import { createProject } from '../../../shared/helpers/project_factory_helpers';
import { getAllBuildingVersions } from '../../../shared/helpers/recursive_element_helpers';
import {
  validateProject,
  validateRecipeProducts,
} from '../../../shared/validation/project.validation';
import { objectPromiseAll } from '../../../shared/helpers/promise.helpers';

/**
 * Import element with connected recipes and products.
 * Will add missing products and recipes to the target organization
 * and remap current IDs to the newly created items.
 * Note: This will not add the project in the store.
 * @param element
 * @param recipes
 * @returns A new element ready to add
 */
export const useImportElement = (): ((
  element: OneOfChildElements,
  recipes: Recipe[],
  products?: ProductRecord,
) => Promise<OneOfChildElements>) => {
  const importProject = useImportProject();

  return useCallback(
    async (element, recipes, products) => {
      const project = createProject({
        owner: 'tmp',
        versions: [{ elements: [element] }],
      });

      // Make a dummy import to import all recipes and products
      const imported = await importProject(project, recipes, products);

      const updatedElement = getAllBuildingVersions(imported)[0].elements[0];

      if (!updatedElement) {
        throw new Error('Import error. No imported elements found');
      }

      return updatedElement;
    },
    [importProject],
  );
};

/**
 * Import project with recipes and products.
 * Will add missing products and recipes to the target organization
 * and remap current IDs to the newly created items.
 * Note: This will not add the project in the store.
 * @param project
 * @param recipes
 * @returns A new project ready to add
 */
export const useImportProject = (): ((
  project: Project,
  recipes: Recipe[],
  products?: ProductRecord,
) => Promise<Project>) => {
  const importProducts = useImportProducts();
  const importRecipes = useImportRecipes();

  return useCallback(
    async (project, recipes, products) => {
      const { project: updatedProject, productMap } = await importProducts(
        project,
        recipes,
        products,
      );

      return await importRecipes(updatedProject, recipes, productMap);
    },
    [importProducts, importRecipes],
  );
};

const useImportProducts = (): ((
  project: Project,
  recipes: Recipe[],
  products?: ProductRecord,
) => Promise<{ project: Project; productMap: ProductRecord }>) => {
  return useCallback(async (project, recipes, products) => {
    const productsLookup = getProductsLookup();
    const organization = getSelectedOrganization(true);

    // Make project contains all products
    validateRecipeProducts(project, recipes, true);

    // From another organization, set it to current one
    if (!project.organizations?.includes(organization)) {
      project.organizations = [organization];
    }

    const importedProductRecord = products ?? getProjectProductsRecord(project);

    // A map of all products that are already available in the target organization.
    // Note that the keys are the id's of imported products
    const correspondingProducts = getCorrespondingProductRecord(
      importedProductRecord,
      productsLookup,
    );

    // Products that should be added
    const productsToAdd = getProductsToAdd(
      importedProductRecord,
      correspondingProducts,
      organization,
      project.owner,
    );

    const addedProductsMap = await getAddedProductsMap(productsToAdd);

    // // Products which can be mapped to an existing ID
    const productsToRemap = mapFilterRecord(
      correspondingProducts,
      (product, id) => (product.id !== id ? product : undefined),
    );

    const addedProductsLookup = toLookup(Object.values(addedProductsMap));

    // All product id's that have new ids (<old id, new product>)
    const productMap: ProductRecord = {
      ...productsToRemap,
      ...addedProductsMap,
    };

    const allProductsWithNewIds = {
      ...productsLookup,
      ...addedProductsLookup,
    };
    const allProductsWithOldToNewIds = {
      ...allProductsWithNewIds,
      ...productMap,
    };

    // Use the custom products that are already available in the target organization
    project = replaceProductIds(project, productMap);

    // Update the product records in all versions
    project = updateProductsInProject(project, allProductsWithNewIds, recipes);

    printRecord('Added products', addedProductsLookup);
    printRecord('Reused products', productsToRemap);

    return {
      project: validateProject(project),
      productMap: validateProductMapForRecipes(
        allProductsWithOldToNewIds,
        recipes,
      ),
    };
  }, []);
};

/**
 * Add products and return a map of the added products <old id, new product>
 * @param products
 * @returns
 */
const getAddedProductsMap = async (
  products: ProductRecord,
): Promise<ProductRecord> => {
  const createProduct = getCreateProduct();
  return objectPromiseAll(
    mapFilterRecord(products, (product) => createProduct(product)),
  );
};

const validateProductMapForRecipes = (
  productMap: ProductRecord,
  recipes: Recipe[],
): ProductRecord => {
  const recipeProducts = getProductIdsInRecipes(...recipes);

  recipeProducts.forEach((id) => {
    if (!productMap[id]) {
      throw new Error(
        `ProductMap validation error. Product with id ${id} is missing in the product map`,
      );
    }
  });
  return productMap;
};

const useImportRecipes = (): ((
  project: Project,
  recipes: Recipe[],
  correspondingProducts: ProductRecord,
) => Promise<Project>) => {
  return useCallback(async (project, recipes, correspondingProducts) => {
    const createRecipe = getCreateRecipe();
    const recipeLookup = getRecipeLookup();
    const organization = getSelectedOrganization(true);

    // Make project contains all products
    validateRecipeProducts(project, recipes, true);

    // Only import recipes that are used in the project (previously we exported all)
    recipes = getRecipesUsedInProject(project, recipes);

    const importedRecipeRecord = toLookup(recipes);

    // All recipes that are already available in the target organization.
    const correspondingRecipes = getCorrespondingRecipeRecord(
      recipes,
      recipeLookup,
    );

    // Recipes which can be mapped to an existing ID
    const recipesToRemap = mapFilterRecord(
      correspondingRecipes,
      (recipe, id) => (recipe.id !== id ? recipe : undefined),
    );

    // All missing recipes
    const recipesToAdd = getRecipesToAdd(
      importedRecipeRecord,
      correspondingRecipes,
      correspondingProducts,
      project.owner,
      organization,
    );

    const addedRecipes = await addRecipes(
      recipesToAdd,
      organization,
      createRecipe,
    );

    project = replaceProjectRecipeIds(project, {
      ...recipesToRemap,
      ...addedRecipes,
    });

    printRecord('Added recipes', addedRecipes);
    printRecord('Reused recipes', recipesToRemap);

    return project;
  }, []);
};
